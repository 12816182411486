import React, { useEffect, useState } from "react";
import { api_url, bnbFee, receivingAddress, TGWContractAddress, title } from "../../config/Config";
import logo_3d from "../../Assets/new_images/logo_3d.png"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import toasted from "../../config/toast";
import _fetch from "../../config/api";
import Loader from "../../config/Loader";
import Web3Set from "../../config/Web3Set";
import leavesTree from '../../Assets/new-theme/login-bg.png';
import leaves_single from '../../Assets/new-theme/leaves-single.png';



const Register = (props) => {
     const [gethash, setGethash] = useState('');

     const [approveLoader, setapproveLoader] = useState('0');
     const [changeSection, setchangeSection] = useState(false);
     const [activeLogin, setactiveLogin] = useState(false);
     const [coinPrice, setcoinPrice] = useState(0);
     const [getSopnserWallet, setgetSopnserWallet] = useState('');
     const [usdtBalance, setusdtBalance] = useState(0);

     const [offsetX, setOffsetX] = useState(0);
     const [offsetY, setOffsetY] = useState(0);
     const location = useLocation();

     const { sponser, userPosition } = useParams();

     const handleMouseMove = (e) => {
          const { clientX, clientY, currentTarget } = e;
          const { offsetWidth, offsetHeight } = currentTarget;

          const xPos = (clientX / offsetWidth) - 0.9; // -0.9 to 0.9
          const yPos = (clientY / offsetHeight) * 2; // -0.9 to 0.9

          setOffsetX(xPos * 35);
          setOffsetY(yPos * 30);
     };

     const [tokenContract, settokenContract] = useState();


     useEffect(() => {

          if (userPosition) {
               setposition(userPosition)
          }
          if (sponser) {
               setsponserId(sponser);
          }

          if (location.pathname === '/register') {

               document.body.classList.add('--register');

          } else {

               document.body.classList.remove('--register');
          }

          return () => {
               document.body.classList.remove('--register');
          };
     }, [location.pathname, tokenContract]);



     const [web3Details, setweb3Details] = useState({ web3: null, address: '' })
     const Navigate = useNavigate();
     const [btnDisable, setbtnDisable] = useState(false);
     const [sponserId, setsponserId] = useState('');
     const [position, setposition] = useState('L');
     const [tokenAmount, settokenAmount] = useState();
     const [web3, setweb3] = useState();
     const [TGWContract, setTGWContract] = useState();

     const generateRandomAlphabet = () => {
          const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const randomIndex = Math.floor(Math.random() * alphabet.length);
          return (alphabet[randomIndex]);
     };

     const submitRegister = async (hash) => {
          // hash.preventDefault()
          setapproveLoader(0);

          setbtnDisable(true)
          let data = {
               "wallet_address": web3Details?.address,
               "sponsor_id": sponserId,
               "position": position,
               "amount": tokenAmount,
               'hash': hash,

          }
          let res = await _fetch(`${api_url}auth/signup`, "POST", data, {})

          if (res?.status === 'success') {
               localStorage.setItem("auth", 'true');
               localStorage.setItem("accessToken", res?.token);
               localStorage.setItem("user", JSON.stringify(res?.data?.user));
               localStorage.setItem("userName", res?.data?.user?.username);

               localStorage.setItem("preview_id", 'false');
               toasted.success(res?.message);
               Navigate("/dashboard");
               setbtnDisable(false)
          }
          else {
               localStorage.clear();
               toasted.error(res?.message);
               console.log(res?.message);
               setbtnDisable(false)
          }

     }
     const [loading, setLoading] = useState(false);


     const handleError = (error) => {
          setapproveLoader(0);

          if (error.code === 4001) {
               toasted.error("User denied transaction signature.");
          } else {
               let msg = error.message.split(":");
               toasted.error("Error " + error.code + " : " + (msg[1] ? msg[1] : error.message));
          }
     };

     console.log('web3Details', web3Details);

     const approveAndSendTokens = async (e) => {
          e.preventDefault()

          // if (loading) return;
          setLoading(true);
          if (tokenAmount >= 30) {
               setapproveLoader(1)
               const web3 = web3Details?.web3;
               const account = web3Details?.address;
               // const tokenContract = web3Details?.tokenContract;
               // const TGWContract = web3Details?.TGWContract;
               const TGWContract = web3Details?.TGWContract;
               console.log('TGWContract', TGWContract);

               console.log('web3', web3, 'account', account, 'tokenContract', tokenContract, 'TGWContract', TGWContract);

               if (web3 && account && tokenContract && TGWContract) {
                    const amountInBNB = bnbFee;
                    const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
                    let gasPrice2 = await web3.eth.getGasPrice();
                    const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

                    const bnbBalances = await web3.eth.getBalance(account);
                    const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

                    const chainIds = await web3.eth.getChainId();
                    const chainId = parseInt(chainIds.toString().replace("n", ""));
                    console.log('chainId', chainId);

                    if (chainId != 56) {
                         setLoading(false);
                         setapproveLoader(0)

                         return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
                    }

                    if (bnbBalance <= amountInWei) {
                         setLoading(false);
                         setapproveLoader(0)

                         return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
                    }

                    // if (web3Details.tokenBalance < tokenAmount) {
                    //      setLoading(false);
                    //      setapproveLoader(0)

                    //      return toasted.error(`Your USDT balance is insufficient. Please add more ${tokenAmount - (web3Details.tokenBalance)} USDT to proceed!`);
                    // }


                    // const nonce = await web3.eth.getTransactionCount(account, 'pending');
                    console.log('allapprovalAmountdddsdsdcwsd');

                    try {



                         const approvalAmount = web3.utils.toWei((tokenAmount), 'ether');
                         console.log('approvalAmount', approvalAmount);

                         const allapprovalAmount = web3.utils.toWei(tokenAmount, 'ether');
                         console.log('allapprovalAmount', allapprovalAmount);
                         try {

                              const gasPriceMain = await web3.eth.getGasPrice();
                              const gasEstimateMain = await tokenContract.methods.approve(TGWContractAddress, allapprovalAmount).estimateGas({ from: account });
                              const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

                              const nonceR = await web3.eth.getTransactionCount(account, 'pending');
                              const approvalClear = await tokenContract.methods.approve(TGWContractAddress, allapprovalAmount).send({
                                   from: account,
                                   gasPrice: gasPrice * 1.3,
                                   gasLimit: gasEstimateMain2,
                                   nonce: nonceR
                              })
                                   .on('transactionHash', async function (hash) {
                                        setapproveLoader('2')
                                        setchangeSection(false)
                                        setLoading(false);
                                   })
                                   .on('receipt', async function (receipt) {
                                        const amounts = parseInt(approvalAmount);
                                        const getEstimatedGas = await TGWContract.methods.buyTokens(approvalAmount.toString()).estimateGas({ from: account });
                                        const estimatedGas2 = parseInt(getEstimatedGas.toString().replace("n", ""));
                                        const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                                        await TGWContract.methods.buyTokens(approvalAmount.toString()).send({
                                             from: account,
                                             gasPrice: gasPrice * 1.3,
                                             gasLimit: estimatedGas2,
                                             nonce: nonce2
                                        })
                                             .on('transactionHash', function (hash) {
                                                  setchangeSection(true)
                                                  setLoading(false);
                                             })
                                             .on('error', function (error) {
                                                  setLoading(false);
                                                  setapproveLoader(0);
                                                  handleError(error);
                                             })
                                             .then(async function (receipt) {
                                                  submitRegister(receipt.transactionHash);
                                                  setactiveLogin(true);
                                                  setGethash(receipt.transactionHash);
                                                  setLoading(false);
                                             });
                                   })
                                   .on('error', function (error) {
                                        setLoading(false);
                                        setapproveLoader(0);
                                        handleError(error);
                                   });

                         } catch (error) {
                              setLoading(false);
                              setapproveLoader(0)
                              handleError(error);
                         }


                    } catch (error) {
                         setLoading(false);
                         setapproveLoader(0)
                         handleError(error);
                    }

               }
          } else {
               setLoading(false);
               setapproveLoader(0);

               return toasted.error('Minimum Amount 30USDT!');

          }
     };






     // const approveAndSendTokens = async (e) => {
     //      e.preventDefault()

     //      // if (loading) return;
     //      console.log('web3Details', web3Details);
     //      setLoading(true);
     //      if (tokenAmount >= 30) {
     //           setapproveLoader(1)
     //           const web3 = web3Details?.web3;
     //           const account = web3Details?.address;
     //           // const tokenContract = web3Details?.tokenContract;
     //           // const TGWContract = web3Details?.TGWContract;
     //           const TGWContract = web3Details?.TGWContract;
     //           console.log('TGWContract', TGWContract);

     //           console.log('web3', web3, 'account', account, 'tokenContract', tokenContract, 'TGWContract', TGWContract);

     //           if (web3 && account && tokenContract && TGWContract) {
     //                const amountInBNB = bnbFee;
     //                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
     //                let gasPrice2 = await web3.eth.getGasPrice();
     //                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

     //                const bnbBalances = await web3.eth.getBalance(account);
     //                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

     //                const chainIds = await web3.eth.getChainId();
     //                const chainId = parseInt(chainIds.toString().replace("n", ""));
     //                console.log('chainId', chainId);

     //                if (chainId != 56) {
     //                     setLoading(false);
     //                     setapproveLoader(0)

     //                     return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
     //                }

     //                if (bnbBalance <= amountInWei) {
     //                     setLoading(false);
     //                     setapproveLoader(0)

     //                     return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
     //                }

     //                // if (web3Details.tokenBalance < tokenAmount) {
     //                //      setLoading(false);
     //                //      setapproveLoader(0)

     //                //      return toasted.error(`Your USDT balance is insufficient. Please add more ${tokenAmount - (web3Details.tokenBalance)} USDT to proceed!`);
     //                // }


     //                // const nonce = await web3.eth.getTransactionCount(account, 'pending');
     //                console.log('allapprovalAmountdddsdsdcwsd');

     //                try {



     //                     const approvalAmount = web3.utils.toWei((tokenAmount), 'ether');
     //                     console.log('approvalAmount', approvalAmount);

     //                     const allapprovalAmount = web3.utils.toWei(tokenAmount, 'ether');
     //                     console.log('allapprovalAmount', allapprovalAmount);
     //                     try {

     //                          const gasPriceMain = await web3.eth.getGasPrice();
     //                          const gasEstimateMain = await tokenContract.methods.approve(receivingAddress, allapprovalAmount).estimateGas({ from: account });
     //                          const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

     //                          const nonceR = await web3.eth.getTransactionCount(account, 'pending');
     //                          const approvalClear = await tokenContract.methods.approve(receivingAddress, allapprovalAmount).send({
     //                               from: account,
     //                               gasPrice: gasPrice * 1.3,
     //                               gasLimit: gasEstimateMain2,
     //                               nonce: nonceR
     //                          })
     //                               .on('transactionHash', async function (hash) {
     //                                    setapproveLoader('2')
     //                                    setchangeSection(false)
     //                                    setLoading(false);
     //                               })
     //                               .on('receipt', async function (receipt) {
     //                                    const amounts = parseInt(approvalAmount);
     //                                    const getEstimatedGas = await tokenContract.methods.transfer(receivingAddress, approvalAmount.toString()).estimateGas({ from: account });
     //                                    const estimatedGas2 = parseInt(getEstimatedGas.toString().replace("n", ""));
     //                                    const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

     //                                    await tokenContract.methods.transfer(receivingAddress, approvalAmount.toString()).send({
     //                                         from: account,
     //                                         gasPrice: gasPrice * 1.3,
     //                                         gasLimit: estimatedGas2,
     //                                         nonce: nonce2
     //                                    })
     //                                         .on('transactionHash', function (hash) {
     //                                              setchangeSection(true)
     //                                              setLoading(false);
     //                                         })
     //                                         .on('error', function (error) {
     //                                              setLoading(false);
     //                                              setapproveLoader(0);
     //                                              handleError(error);
     //                                         })
     //                                         .then(async function (receipt) {
     //                                              submitRegister(receipt.transactionHash);
     //                                              setactiveLogin(true);
     //                                              setGethash(receipt.transactionHash);
     //                                              setLoading(false);
     //                                         });
     //                               })
     //                               .on('error', function (error) {
     //                                    setLoading(false);
     //                                    setapproveLoader(0);
     //                                    handleError(error);
     //                               });

     //                     } catch (error) {
     //                          setLoading(false);
     //                          setapproveLoader(0)
     //                          handleError(error);
     //                     }


     //                } catch (error) {
     //                     setLoading(false);
     //                     setapproveLoader(0)
     //                     handleError(error);
     //                }

     //           }
     //      } else {
     //           setLoading(false);
     //           setapproveLoader(0);

     //           return toasted.error('Minimum Amount 30USDT!');

     //      }
     // };


     return (
          <div onMouseMove={handleMouseMove}>
               <main>
                    <Web3Set stateprop={setweb3Details} setweb3={setweb3} setusdtBalance={setusdtBalance} setTGWContract={setTGWContract} settokenContract={settokenContract} />

                    <div className="__register-page" >
                         <div className="__container">

                              <div className="leaves-single-gh">
                                   <img src={leaves_single} alt='icon' />

                              </div>

                              <div className="row align-items-center">
                                   <div className="col-lg-6">
                                        <div className="__left-register">
                                             <h2>Registration in <br /><span style={{ color: "#e8cc56" }}>{title}</span></h2>
                                             <h5>Your Balance is: {usdtBalance?.toFixed(4)} USDT</h5>
                                             {/* <h3 className='text-danger'>Important Notice !!!</h3>
                                             <p className='mb-0 '>New Registration/Upgrade and Withdrawal will be off  From 12:00 PM To 3:00 PM </p>
                                             <p className=''>The inconvenience caused is deeply regretted.</p> */}
                                             <form onSubmit={approveAndSendTokens}>
                                                  <input type="text" placeholder="Sponser ID" onChange={(e) => { setsponserId(e.target.value) }} value={sponserId} required />

                                                  <br />
                                                  <input type="text" placeholder="Wallet Address" value={web3Details?.address} readOnly required />

                                                  <br />

                                                  <input type="number" placeholder="Enter Amount" onChange={(e) => { settokenAmount(e.target.value) }} value={tokenAmount} required />
                                                  <br />

                                                  <select className="form-select" onChange={(e) => { setposition(e.target.value) }} value={position} required>
                                                       <option value={'L'}>Left</option>
                                                       <option value="R">Right</option>
                                                  </select>

                                                  {/* <div className="__conditions">
                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_1" name="check" value="" />
                                                            <label htmlFor="check_1"> <span> </span>sponser ID: sponser Not find </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_3" name="check" value="" />
                                                            <label htmlFor="check_3"> <span> </span>Network: </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_4" name="check" value="" />
                                                            <label htmlFor="check_4"> <span> </span>Registration: not available </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_5" name="check" value="" />
                                                            <label htmlFor="check_5"> <span> </span>Balance: min - </label>
                                                       </div>

                                                       <div className="checkbox-wrapper-24">
                                                            <input type="checkbox" id="check_6" name="check" value="" />
                                                            <label htmlFor="check_6"> <span> </span>Approve {title.toLocaleLowerCase()}: Required </label>
                                                       </div>
                                                  </div> */}


                                                  <div className="agree_terms">
                                                       <div>< input type="checkbox" required /></div>
                                                       <div>I accept the terms and conditions.</div>
                                                  </div>



                                                  <button type="submit" className="submit-btn btn" disabled={loading}>
                                                       {approveLoader == 0 ? 'Register Now' : approveLoader == 1 ? <Loader LoaderTxt={' Waiting for approval!'} /> : <Loader LoaderTxt={' Waiting for confirmation'} />}

                                                  </button>
                                                  <p className='mt-4'>Register Transaction failed.  <Link to={'/failed-register'} >Click here to retry</Link>  or get assistance.</p>

                                             </form>
                                        </div>
                                   </div>

                                   <div className="col-lg-6">
                                        <div className="__img-sec" >
                                             <div className="__tree-img">
                                                  <img src={leavesTree} alt='icon' />
                                             </div>
                                             <img src={logo_3d} alt={title + '-logo'} style={{ transform: `translate(${offsetX}px, ${offsetY}px) scale(0.8)` }} />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </main>
          </div>
     );
};

export default Register;


