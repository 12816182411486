import { ArrowDropDownCircle, East, Info, West } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { api_url, title, titleDescription } from '../../../config/Config';
import __activePerson from '../../../Assets/new-theme/active.png';
// import __activePerson from '../../../Assets/new-theme/inactive.png';
import __addNew from '../../../Assets/new-theme/add-new.png';
import { Link, useParams } from 'react-router-dom';
import _fetch from '../../../config/api';

const BinaryTree = (props: any) => {

    const [accordion, setAccordion] = useState(false);
    const { username } = useParams();



    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        getAllTeamTree()
    }, [username]);


    const [teamLe1, setteamLe1] = useState({ level: 1, l1: null, l2: null });
    const [teamLe2, setteamLe2] = useState({ level: 2, l21: null, l22: null, l23: null, l24: null });
    const [teamLe3, setteamLe3] = useState({ level: 3, l31: null, l32: null, l33: null, l34: null, l35: null, l36: null, l37: null, l38: null });




    const getAllTeamTree = async () => {
        // setLoading(true);
        // if (levelSearch != "" || downloadID != "") {
        //   var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
        // } else {
        //   var searchData = "";
        // }
        let data = await _fetch(`${api_url}network/GenelogyTree?username=${username}`, 'GET', {})

        if (data?.status === 'success') {
            console.log('data?.data?.[0]', data?.data);

            setteamLe1(data?.data?.[0]);
            setteamLe2(data?.data?.[1]);
            setteamLe3(data?.data?.[2]);

            //   setLoading(false);
        }
    }
    return (
        <div className='binarytree-sec'>
            <div className='row'>
                {/* <div className='col-12'>
                    <div className={accordion ? '__heading_sa show' : '__heading_sa'}>
                        <div className='__top-heading'>
                            <p> Binary Team Structure</p>

                            <form>
                                <select className="form-select">
                                    <option>Search Type</option>
                                    <option>Search By Username</option>
                                    <option>Search By Name</option>
                                </select>

                                <div className="input-group">
                                    <input type="search" className="form-control" placeholder="Search" />
                                </div>

                                <button type='button' onClick={() => setAccordion(!accordion)} className='btn'> <ArrowDropDownCircle /></button>
                            </form>
                        </div>

                        <div className='__bottom-heading'>
                            <div className='details-1'>
                                <West/>
                                <p>Left Total Team : <span>0</span></p>
                                <p>Left ZS : <span>0</span>	</p>
                                <p>Left Business : <span>0</span></p>
                            </div>

                            <div className='details-1'>
                                <East/>
                                <p>Right Total Team :<span>0</span></p>
                                <p>Right ZS : <span>0</span>	</p>
                                <p>Right Business : <span>0</span></p>
                            </div>

                        </div>
                    </div>
                </div> */}

                <div className='col-12'>
                    <section className="management-tree">
                        <div className="mgt-container">
                            <div className="mgt-wrapper">

                                <div className="mgt-item">

                                    <div className="mgt-item-parent">

                                        <div className="person">
                                            <img src={__activePerson} alt='icon' />
                                            <p className="name admin-name">{username}    <br />         {title}</p>

                                            {/* <div className='__drophover'>
                                                <ul>
                                                    <li> <p>Username: Admin</p> </li>
                                                    <li> <p> Package: 5000</p> </li>
                                                    <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                    <li>
                                                        <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                    </li>
                                                    <li>
                                                        <p> Left points : 36850 | Right points : 0 </p>
                                                    </li>
                                                    <li>
                                                        <p>Left Business : 36850 | Right Business : 0</p>
                                                    </li>
                                                </ul>
                                            </div> */}

                                        </div>

                                    </div>

                                    <div className="mgt-item-children">

                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    {teamLe1?.l1 ? <div className="person activePerson">
                                                        <img src={__activePerson} alt="icon" />
                                                      <Link to={`/team-tree/${teamLe1?.l1}`}>  <p className="name">{teamLe1?.l1} <br />   {title}</p></Link>

                                                        {/* <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: Admin</p> </li>
                                                                <li> <p> Package: 5000</p> </li>
                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                <li>
                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div> :
                                                        <div className="person add-new">
                                                            <img src={__addNew} alt="icon" />

                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                        </div>
                                                    }

                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            {teamLe2?.l21 ? <div className="person">
                                                                <img src={__activePerson} alt="icon" />
                                                                <Link to={`/team-tree/${teamLe2?.l21}`}>   <p className="name">{teamLe2?.l21} <br /> {title}</p></Link>

                                                                {/* <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: Admin</p> </li>
                                                                        <li> <p> Package: 5000</p> </li>
                                                                        <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                        <li>
                                                                            <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p> Left points : 36850 | Right points : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Left Business : 36850 | Right Business : 0</p>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}
                                                            </div> :
                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l31 ? <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <Link to={`/team-tree/${teamLe3?.l31}`}> <p className="name">{teamLe3?.l31} <br />  {title}</p></Link>

                                                                    {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l32 ?
                                                                    <div className="person">
                                                                        <img src={__activePerson} alt="icon" />
                                                                        <Link to={`/team-tree/${teamLe3?.l32}`}> <p className="name">{teamLe3?.l32} <br /> {title}</p></Link>

                                                                        {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                    </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">
                                                                {teamLe2?.l22 ? <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <Link to={`/team-tree/${teamLe2?.l22}`}>  <p className="name">{teamLe2?.l22} <br /> {title}</p></Link>

                                                                    {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">
                                                                    {teamLe3?.l33 ? <div className="person">
                                                                        <img src={__activePerson} alt="icon" />
                                                                        <Link to={`/team-tree/${teamLe3?.l33}`}>   <p className="name">{teamLe3?.l33} <br /> {title}</p></Link>

                                                                        {/* <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: Admin</p> </li>
                                                                                <li> <p> Package: 5000</p> </li>
                                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                                <li>
                                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    {teamLe3?.l34 ? <div className="person">
                                                                        <img src={__activePerson} alt="icon" />
                                                                        <Link to={`/team-tree/${teamLe3?.l34}`}><p className="name">{teamLe3?.l34} <br /> {title}</p></Link>

                                                                        {/* <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: Admin</p> </li>
                                                                                <li> <p> Package: 5000</p> </li>
                                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                                <li>
                                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    {teamLe1?.l2 ? <div className="person">
                                                        <img src={__activePerson} alt="" />
                                                        <Link to={`/team-tree/${teamLe1?.l2}`}><p className="name"> {teamLe1?.l2} <br />     {title}</p></Link>

                                                        {/* <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: Admin</p> </li>
                                                                <li> <p> Package: 5000</p> </li>
                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                <li>
                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div> :

                                                        <div className="person add-new">
                                                            <img src={__addNew} alt="icon" />

                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                        </div>}
                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            {teamLe2?.l23 ? <div className="person">
                                                                <img src={__activePerson} alt="icon" />
                                                                <Link to={`/team-tree/${teamLe2?.l23}`}><p className="name">{teamLe2?.l23} <br /> {title} </p></Link>

                                                                {/* <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: Admin</p> </li>
                                                                        <li> <p> Package: 5000</p> </li>
                                                                        <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                        <li>
                                                                            <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p> Left points : 36850 | Right points : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Left Business : 36850 | Right Business : 0</p>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}
                                                            </div> :

                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                </div>}
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l35 ? <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <Link to={`/team-tree/${teamLe3?.l35}`}><p className="name">{teamLe3?.l35} <br /> {title}</p></Link>

                                                                    {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            {/* <div className="mgt-item-child">
                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to="#" className="btn"> Add New</Link>
                                                                </div>
                                                            </div> */}

                                                            <div className="mgt-item-child">
                                                                {teamLe3?.l36 ? <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <Link to={`/team-tree/${teamLe3?.l36}`}><p className="name">{teamLe3?.l36} <br /> {title}</p></Link>

                                                                    {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">
                                                                {/* <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to="#" className="btn"> Add New</Link>
                                                                </div> */}
                                                                {teamLe2?.l24 ? <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <Link to={`/team-tree/${teamLe2?.l24}`}> <p className="name">{teamLe2?.l24} <br />{title}</p></Link>

                                                                    {/* <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div> */}
                                                                </div> :

                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                    </div>}
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">
                                                                    {/* <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to="#" className="btn"> Add New</Link>
                                                                    </div> */}

                                                                    {teamLe3?.l37 ? <div className="person">
                                                                        <img src={__activePerson} alt="icon" />
                                                                        <Link to={`/team-tree/${teamLe3?.l37}`}><p className="name">{teamLe3?.l37} <br /> {title}</p></Link>

                                                                        {/* <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: Admin</p> </li>
                                                                                <li> <p> Package: 5000</p> </li>
                                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                                <li>
                                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div> :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    {/* <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to="#" className="btn"> Add New</Link>
                                                                    </div> */}

                                                                    {teamLe3?.l38 ? <div className="person">
                                                                        <img src={__activePerson} alt="icon" />
                                                                        <Link to={`/team-tree/${teamLe3?.l38}`}><p className="name">{teamLe3?.l38} <br /> {title}</p></Link>

                                                                        {/* <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: Admin</p> </li>
                                                                                <li> <p> Package: 5000</p> </li>
                                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                                <li>
                                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                        :

                                                                        <div className="person add-new">
                                                                            <img src={__addNew} alt="icon" />

                                                                            <Link to={`/register/${username}`} className="btn"> Add New</Link>
                                                                        </div>}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>



        </div>
    );
}

export default BinaryTree;
