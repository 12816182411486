import React, { useEffect, useState } from 'react'
import { TabPanel, Tabs } from 'react-tabs'
import { api_url, titleDescription } from '../../config/Config';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import { AccountBalanceWallet, MonetizationOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import SupportTab from '../../Private/SupportTab';



const PayoutRequestNonWorking = (props: any) => {
    const { t } = useTranslation();
    const [preview, setpreview] = useState('true');

    useEffect(() => {
        const previewCheck: any = localStorage.getItem('preview_id');
        setpreview(previewCheck)
        document.title = `${props.pageTitle} | ${titleDescription}`;
    }, []);

    const [balance, setBalance] = useState(0);
    const [amount, setamount] = useState(0);

    const [deduction, setDeduction] = useState(0);
    const [walletAddresses, setWalletAddresses] = useState({ BEP20: "", TRC20: "" });
    const [activeTab, setActiveTab] = useState(0);
    const [selectedIncomeRadio, setSelectedIncomeRadio] = useState(null);
    const [selectedWalletRadio, setSelectedWalletRadio] = useState('BEP20');
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [otp, setotp] = useState();
    const [btnDisable, setbtnDisable] = useState(false);
    const [task, setTaskDone] = useState(false);


    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        geTotalIncome();
        getUserInfo();

    }, []);

    const geTotalIncome = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}withdraw/check-non-balance`, "GET", {})
        if (res?.status === 'success') {
            setLoading(false);
            setBalance(res?.balance);
            setDeduction(res?.deduction);
            // setamount(res?.balance);
        }

        let data = await _fetch(`${api_url}withdraw/check-wallet-addresses`, "GET", {})
        if (data?.status === "success") {
            setLoading(false);
            setWalletAddresses(data?.data);
        }
    }

    const handleIncomeRadioChange = (value: any) => {
        setSelectedIncomeRadio(value);
    };

    const handleSecondTabRadioChange = (value: any) => {
        setSelectedWalletRadio(value);
    };

    const handleNext = () => {
        if (balance > 0) {
            // console.log('activeTab', activeTab);

            if (activeTab == 1 && SecurityType == 'none') {
                toasted.error("Please activate 2fa ");
                return;
            }
            if (activeTab === 1 && !selectedWalletRadio) {
                toasted.error("Please select atleast one value");
                return;
            }
            setActiveTab((prevTab) => prevTab + 1);
        }
        else {
            toasted.error("Insufficient balance to proceed ")
        }
        // console.log("!walletAddresses", !walletAddresses);

        if (walletAddresses?.BEP20 === null) {
            setOpen(true);
        }
    };


    const withdraw = async () => {
        setbtnDisable(true)

        let data = {
            "amount": amount,
            "type": selectedWalletRadio,
            'otp': otp
        }

        let res = await _fetch(`${api_url}withdraw/non-withdrawal`, "POST", data, {})
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setActiveTab(0);
            geTotalIncome();
            setbtnDisable(false)
            setTaskDone(false)

        }
        else {
            setbtnDisable(false)
            toasted.error(res?.message);
        }
    }

    const closeModal = () => {
        setOpen(true);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/profile', { state: { selectedTab: 'Wallet' } });
    };


    const [SecurityType, setSecurityType] = useState('none');

    const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
    async function getUserInfo() {
        var d = await _fetch(`${api_url}user/userInfo`, 'GET', {}, {});
        if (d?.status === 'success') {
            setUserInfo(d?.data?.data);

            setSecurityType(d?.data?.data?.security_type);


        }
    }


    const links = [
        { name: t("Payout Request"), path: '/non-working-request' },
        { name: t("Payout history"), path: '/non-pay-history' }
    ];

    const headings = [t('Choose Withdrawal Funds'), t('Choose Your Wallet'), t('Confirm Payout Information')];

    const getListClassName = () => {
        switch (activeTab) {
            case 0:
                return 'list active';
            case 1:
                return 'list active active-50';
            case 2:
                return 'list active active-100';
            default:
                return 'list';
        }
    };




    return (
        <>

            <main className=''>

                <SupportTab links={links} />

                
                <div className="request_main">
                    <div className="request_inner">

                        <div className={task ? "__steps done" : "__steps  soon"}>
                            <div className="titlecard"> {headings[activeTab]}</div>

                            <ul className={getListClassName()}>
                                <li>1 </li>
                                <li>2</li>
                                <li>3</li>
                            </ul>
                        </div>

                        <Tabs selectedIndex={activeTab} >

                            <TabPanel>

                                <div className="panel_section">
                                    <div className='row'>
                                        <div className='col-xl-12 col-lg-12'>
                                            <div className='card income_card'>
                                                <div className="checkbox__boxs">
                                                    <label className="checkbox-wrapper" htmlFor='totalIncome'>
                                                        <input type="radio" name="Subject" id="totalIncome" className="checkbox-input" value="option1" onChange={() => handleIncomeRadioChange('option1')} checked />

                                                        <span className="checkbox-tile">
                                                            <div className="card-body">
                                                                <div className='left-sec'>
                                                                    <div className='icon__leds1'>
                                                                        <MonetizationOn />
                                                                    </div>

                                                                    <div className="total_income">
                                                                        <h6 className='info'>{balance?.toFixed(4)}TGW</h6>
                                                                        <h6 className='total'> {t("Total Income")} </h6>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="button_sec">
                                        <a className="btn" onClick={handleNext} >{t("continue")}</a>
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel>

                                <div className="panel_section">
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='card income_card'>
                                                <div className="checkbox__boxs">
                                                    <label className="checkbox-wrapper" htmlFor='BEP20_address'>
                                                        <input type="radio" className="checkbox-input" id="BEP20_address" name="Subject" value="BEP20" onChange={() => handleSecondTabRadioChange('BEP20')} checked />

                                                        <span className="checkbox-tile">
                                                            <div className="card-body">
                                                                <div className='left-sec'>
                                                                    <div className='icon__leds1'>
                                                                        <AccountBalanceWallet />
                                                                    </div>

                                                                    <div className="total_income">
                                                                        <h6 className='info'> BEP20: {walletAddresses?.BEP20}</h6>
                                                                        <h6 className='total'> {t("Wallet Type")} </h6>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='col-lg-12'>
                    <div className='card income_card'>
                        <div className="checkbox__boxs">
                            <label className="checkbox-wrapper" htmlFor='TRC20_address'>
                                <input type="radio" className="checkbox-input" id="TRC20_address" name="Subject" value="TRC20" onChange={() => handleSecondTabRadioChange('TRC20')}  />

                                <span className="checkbox-tile">
                                    <div className="card-body">
                                        <div className='left-sec'>
                                            <div className='icon__leds1'>
                                                <AccountBalanceWalletIcon />
                                            </div>

                                            <div className="total_income">
                                                <h6 className='total'> {t("Wallet Type")} </h6>
                                                <h6 className='info'> BEP20: {walletAddresses?.TRC20}</h6>
                                            </div>
                                        </div>

                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div> */}
                                    </div>

                                    <div className="button_sec">
                                        {/* <a className="btn" onClick={handleNext}   >{t("continue")}< ArrowForwardIosIcon /></a> */}
                                        <a className="btn" onClick={() => { handleNext() }}  > {t("continue")}</a>
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel>

                                <div className="panel_section">
                                    <div className="panel_inner">
                                        <p>{t("Balance")}: <span className='amount'>{balance?.toFixed(4)} TGW</span></p>
                                        <div className="pay-req">
                                            <h6>{t("Payout Request")}:</h6>
                                            {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                            <input type='number' className='payout-request' onChange={(e: any) => { e.target.value >= 0 ? setamount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} />
                                            {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                        </div>
                                        <div className='pay-req_charges'>
                                            <div className="pay-req">
                                                <h6>{t("Admin Handling Charges")}:</h6>
                                                <h6 className='amount'>{((amount / 100) * (100 * deduction))?.toFixed(4)} TGW</h6>
                                            </div>

                                            <div className="pay-req">
                                                <h6>{t("Release Payout")}:</h6>
                                                <h6 className='amount'>{(amount / 100 * (100 - (100 * deduction)))?.toFixed(4)} TGW</h6>
                                            </div>
                                        </div>

                                        <div className="pay-req">
                                            <h6>{t("Auth Code")}:</h6>
                                            {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                            <input type='number' className='payout-request' onChange={(e: any) => { setotp(e.target.value) }} value={otp} />
                                            {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                        </div>

                                        <div className="button_sec">
                                            <button type='button' className="btn button_style " onClick={() => { withdraw(); setTaskDone(true) }} disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <Loader LoaderTxt={'Please Wait...'} />
                                                }
                                                {btnDisable === false && <>{t("Confirmation")}</>}

                                            </button>
                                        </div>
                                    </div>


                                </div>


                            </TabPanel>
                        </Tabs>
                    </div>
                </div>

                {open && (

                    <Modal open={open} onClose={closeModal} center
                        classNames={{
                            modal: "request_modal",
                        }}>
                        <div className="request_inner">
                            <p>Please update your wallet addresses</p>
                            {/* <Link to={{ pathname: "/profile", state: {selectedTab: 'Wallet'}} as any} className='button_style text-deco-none'>Click Here</Link> */}
                            <button onClick={handleClick} className='new_button_css_dashboard'>
                                Click Here
                            </button>

                        </div>
                    </Modal>
                )}
            </main>

        </>

    )

}

export default PayoutRequestNonWorking