import React, { useState } from "react";
import { api_url, title } from "../../config/Config";
import logo_3d from "../../Assets/new_images/logo_3d.png";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import {
    ArrowDownward,
    ArrowUpward,
    ContentCopy,
    OpenInNew,
    Wallet,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import Web3Set from "../../config/Web3Set";
import { useWeb3Con } from "../../config/Web3ContextProvider";
import leavesTree from '../../Assets/new-theme/login-bg.png';
import leaves_single from '../../Assets/new-theme/leaves-single.png';


const Login = (props) => {
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [web3, setweb3] = useState();
    const { WalletAddress, web3data } = useWeb3Con();
    const [TGWContract, setTGWContract] = useState();
    const [tokenContract, settokenContract] = useState();
    const Navigate = useNavigate();
    const [btnDisable, setbtnDisable] = useState(false);
    const [usdtBalance, setusdtBalance] = useState(0);

    const handleMouseMove = (e) => {
        const { clientX, clientY, currentTarget } = e;
        const { offsetWidth, offsetHeight } = currentTarget;

        // Calculate cursor position relative to the element
        const xPos = clientX / offsetWidth - 0.9; // -0.9 to 0.9
        const yPos = (clientY / offsetHeight) * 2; // -0.9 to 0.9

        // Update offset state based on cursor position
        setOffsetX(xPos * 70); // Adjust multiplier for intensity
        setOffsetY(yPos * 35);
    };

    var Contracts = "0x3fFe6173B63f1045962EbDc1A1";

    // const web3 = new Web3(window.ethereum);

    async function checkWalletCanSign() {
        try {
            // const web3 = new Web3(Web3.givenProvider);
            const accounts = await web3.eth.getAccounts();

            if (accounts.length === 0) {
                return false; // No accounts connected
            }
            console.log(accounts[0]);
            const account = accounts[0];
            const message = "Please sign this message to verify your wallet.";
            const signature = await web3.eth.personal.sign(message, account, "");
            return true; // If sign is successful, it's not a watch-only wallet
        } catch (error) {
            if (error.message.includes("User denied message signature")) {
                return false; // User denied the message signing
            }
            return false; // If there's an error signing, it's likely a watch-only wallet
        }
    }

    const [web3Details, setweb3Details] = useState({ web3: null, address: "" });
    const submitLogin = async () => {
        const canSign = await checkWalletCanSign();
        console.log("canSign", canSign);
        if (canSign) {
            setbtnDisable(true);
            let data = {
                wallet_address: web3Details?.address,
            };
            let res = await _fetch(`${api_url}auth/login`, "POST", data, {});

            if (res?.status === "success") {
                localStorage.setItem("auth", "true");
                localStorage.setItem("accessToken", res?.token);
                localStorage.setItem("user", JSON.stringify(res?.data?.user));
                localStorage.setItem("userName", res?.data?.user?.username);
                localStorage.setItem("preview_id", 'false');

                toasted.success(res?.message);
                Navigate("/dashboard");
                setbtnDisable(false);
            } else {
                localStorage.clear();
                toasted.error(res?.message);
                console.log(res?.message);
                setbtnDisable(false);
            }
        } else {
            // Handle the case where the wallet cannot sign
            toasted.error("Watch wallet cannot be used for login!");
        }
    };


    const copyToClipboard = (address, message) => {

        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(message + ' copied successfull!');
      }
    return (
        <main onMouseMove={handleMouseMove}>
            <ParallaxProvider>
                <Web3Set stateprop={setweb3Details} setweb3={setweb3} setusdtBalance={setusdtBalance} setTGWContract={setTGWContract} settokenContract={settokenContract} />

                <Parallax speed={-7}>
                    <section className="preview-sec">
                        <div className="__card-data">

                            <div className="__tree-img">
                                <img src={leavesTree} alt='icon' />
                            </div>

                            <div
                                className="__img-sec"
                                style={{ transform: `translate(${offsetX}px, ${offsetY}px)` }}>
                                <img src={logo_3d} alt={title + "-logo"} />
                            </div>

                            <h2>Account preview </h2>
                            <p>
                                - Look up any {title} member account in preview mode. Enter ID
                                or {title} address to preview to view a random account.
                            </p>
                            <p className="mb-0">
                                - Enter ID or {title} address to preview to view a random
                                account.
                            </p>
                            <div className="__logs">
                                <div className="__inputs">
                                    <input type="text" className="input-form" placeholder={`Enter  Wallet Address`} value={web3Details?.address} />
                                    <button type="button" className="btn" onClick={() => { submitLogin(); }} > Login </button>
                                </div>

                                {/* <button type="button" className="btn check-btn">check demo</button> */}
                            </div>
                        </div>
                    </section>
                </Parallax>

                <section className="infomation-sec">

                    <div className="__howit-1">
                        <img src={leaves_single} alt='icon' />
                    </div>

                    <Tabs>
                        <div className="heading-sec">
                            <Parallax speed={4}>
                                <h2>Support {title} Movement</h2>

                                {/* <TabList>
                                    <Tab> Info activity</Tab>
                                    <Tab> History activity</Tab>
                                </TabList> */}
                            </Parallax>
                        </div>

                        <TabPanel>
                            <div className="info-row">
                                <div className="row justify-content-center ">
                                    {/* <div className="col-12">
                                        <Parallax speed={5}>
                                            <div className="row info-row-row">
                                                <div className="col-md-6">
                                                    <div className="__info-card">
                                                        <div className="__top">
                                                            <h6> Members total</h6>
                                                            <h2>
                                                                463420
                                                                <small className="__profit">
                                                                    <ArrowUpward /> 922
                                                                </small>
                                                            </h2>
                                                        </div>

                                                        <div className="__bottom">
                                                            <h6> Members total</h6>
                                                            <h2>
                                                                463420
                                                                <small className="__loss">
                                                                    <ArrowDownward />
                                                                </small>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="__info-card">
                                                        <div className="__top">
                                                            <h6> Members total</h6>
                                                            <h2>
                                                                463420
                                                                <small className="__profit">
                                                                    <ArrowUpward /> 922
                                                                </small>
                                                            </h2>
                                                        </div>

                                                        <div className="__bottom">
                                                            <h6> Members total</h6>
                                                            <h2>
                                                                463420
                                                                <small className="__loss">
                                                                    <ArrowDownward />
                                                                </small>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Parallax>
                                    </div> */}

                                    <div className="col-12">
                                        <Parallax speed={15}>
                                            <div className="__info-card __info-card-shadow">
                                                <div className="__address">
                                                    {/* <h6>{title} Contracts</h6> */}
                                                    <p>
                                                    Copy and share the contract address to spread the word about our eco-friendly movement!
                                                    Support sustainability by sharing our mission with others. Together, we can make a lasting impact for a greener tomorrow!
                                                    </p>
                                                    <div className="address-btn">
                                                        <h6> {'0x80C260cd80d8a2855b554A7d5001BD06421a6fd4'}
                                                            <button type="button" className="copy-btn" onClick={() => {copyToClipboard('0x80C260cd80d8a2855b554A7d5001BD06421a6fd4','Contract Address ')}}>
                                                                <ContentCopy />
                                                            </button>
                                                        </h6>

                                                    </div>
                                                </div>
                                            </div>
                                        </Parallax>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        {/* <TabPanel>
                            <div className="info-row">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <Parallax speed={8}>
                                            <div className="table-sec">
                                                <div className="table-sec-responsive">
                                                    <div className="table-tr">
                                                        <div className="info_coin">
                                                            <div className="__img-circle">
                                                                <Wallet />
                                                            </div>
                                                            <h6>ID:2504781</h6>
                                                        </div>

                                                        <div className="price_coin">
                                                            <p>
                                                                +2.00 <span>USDT in x2</span>
                                                            </p>
                                                        </div>

                                                        <div className="trade_coin">
                                                            <Link className="trade_button" to="/">
                                                                <OpenInNew />
                                                            </Link>
                                                            <p>12 minutess</p>
                                                        </div>
                                                    </div>

                                                    <div className="table-tr">
                                                        <div className="info_coin">
                                                            <div className="__img-circle">
                                                                <Wallet />
                                                            </div>
                                                            <h6>ID:2504781</h6>
                                                        </div>

                                                        <div className="price_coin">
                                                            <p>
                                                                +2.00 <span>USDT in x2</span>
                                                            </p>
                                                        </div>

                                                        <div className="trade_coin">
                                                            <Link className="trade_button" to="/">
                                                                <OpenInNew />
                                                            </Link>
                                                            <p>12 minutess</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-tr">
                                                        <div className="info_coin">
                                                            <div className="__img-circle">
                                                                <Wallet />
                                                            </div>
                                                            <h6>ID:2504781</h6>
                                                        </div>

                                                        <div className="price_coin">
                                                            <p>
                                                                +2.00 <span>USDT in x2</span>
                                                            </p>
                                                        </div>

                                                        <div className="trade_coin">
                                                            <Link className="trade_button" to="/">
                                                                <OpenInNew />
                                                            </Link>
                                                            <p>12 minutess</p>
                                                        </div>
                                                    </div>

                                                    <div className="table-tr">
                                                        <div className="info_coin">
                                                            <div className="__img-circle">
                                                                <Wallet />
                                                            </div>
                                                            <h6>ID:2504781</h6>
                                                        </div>

                                                        <div className="price_coin">
                                                            <p>
                                                                +2.00 <span>USDT in x2</span>
                                                            </p>
                                                        </div>

                                                        <div className="trade_coin">
                                                            <Link className="trade_button" to="/">
                                                                <OpenInNew />
                                                            </Link>
                                                            <p>12 minutess</p>
                                                        </div>
                                                    </div>

                                                    <div className="table-tr">
                                                        <div className="info_coin">
                                                            <div className="__img-circle">
                                                                <Wallet />
                                                            </div>
                                                            <h6>ID:2504781</h6>
                                                        </div>

                                                        <div className="price_coin">
                                                            <p>
                                                                +2.00 <span>USDT in x2</span>
                                                            </p>
                                                        </div>

                                                        <div className="trade_coin">
                                                            <Link className="trade_button" to="/">
                                                                <OpenInNew />
                                                            </Link>
                                                            <p>12 minutess</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-btn-sec">
                                                <button className="btn" type="button">
                                                    See More activity
                                                </button>
                                            </div>
                                        </Parallax>
                                    </div>
                                </div>
                            </div>
                        </TabPanel> */}
                    </Tabs>
                </section>
            </ParallaxProvider>
        </main>
    );
};

export default Login;
