import React, { useState, useEffect } from 'react';

function CountdownTimer(props) {
     // console.log('props', props);
     // Calculate the target date by adding 50 days to the start date
     const startDate = new Date(props.time);
     const targetDate = new Date(startDate.getTime() + 50 * 24 * 60 * 60 * 1000);

     const [timeLefts, setTimeLefts] = useState(calculateTimeLeft());
     const [timeLeftCheck, setTimeLeftCheck] = useState(0);


     useEffect(() => {
          const timer = setInterval(() => {
               setTimeLefts(calculateTimeLeft());
               // setTimeLeftCheck(1)

          }, 1000);

          // Cleanup interval on component unmount
          return () => clearInterval(timer);
     }, [timeLefts]);

     function calculateTimeLeft() {
          const now = new Date();
          const difference = targetDate - now;

          let timeLeft = {};

          if (difference > 0) {
               timeLeft = {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
               };
               // if(timeLeft.seconds > 0) {
               // }
          } else {
               // Timer has expired
               timeLeft = {
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
               };
          }

          //  console.log('timeLeftssss',timeLeft)


          return timeLeft;
     }

     return (
          <>
               {/* { console.log('timeLeft',timeLefts)} */}
               {(timeLefts.days > 0 || timeLefts.hours > 0 || timeLefts.minutes > 0) &&
                    <div className='income_a w-100'>
                         <div className='__timer-outer w-100'>
                              <h4>Booster time left</h4>
                              <h3 className='times_sec text-danger'>
                                   <div className='time-box'>{timeLefts.days}  <span>Days</span></div>
                                   <div className='time-box'>{timeLefts.hours} <span>Hours</span></div>
                                   <div className='time-box'>{timeLefts.minutes}  <span>Minutes</span></div>
                                   <div className='time-box'>{timeLefts.seconds} <span>Seconds</span> </div>
                              </h3>
                         </div>
                         <h4 className='text-danger mb-1 mt-4 text-center'>BOOSTER REQUIRED REMAINING :  ${(props.package * 2) - props.direct_business} </h4>

                    </div>
               }
          </>
     );
}

export default CountdownTimer;
