// menuItems.ts


import { Dashboard, ShoppingBasket, Star, AccountBalanceWallet, Groups, Description, Payment, AutoStories, Help, Logout } from '@mui/icons-material';
import _fetch from '../config/api';
import { api_url } from '../config/Config';
import toasted from '../config/toast';

const logOut = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

        toasted.success(data?.message);
        localStorage.clear();
        window.location.reload();
    } else {
        toasted.error(data?.message);
    }
}

var getUseranme =  localStorage.getItem("userName");
console.log('getUseranme',getUseranme);


export const menuItems = [
    { eventKey: "0", path: "/dashboard", icon: <Dashboard />, label: 'Dashboard' },
    {
        eventKey: "1", icon: <ShoppingBasket />, label: "Profile", subMenu: [
            { path: "/profile", icon: <Star />, label: "Google Authenticator" },
            // { path: "/update-wallet", icon: <Star />, label: 'Update Wallet Address' },
            // { path: "/walletHistory", icon: <Star />, label: 'Wallet Activities' },
        ]
    },
    { eventKey: "2", path: "/main-overview", icon: <AccountBalanceWallet />, label: 'Overview' },
    {
        eventKey: "3", path: "/team", icon: <Groups />, label: 'Structure', subMenu: [
            { path: "/team", icon: <Star />, label: 'Structure' },
            { path: "/downline-report/L", icon: <Star />, label: 'Left Structure' },
            { path: "/downline-report/R", icon: <Star />, label: 'Right Structure' },
            { path: "/team-tree/"+getUseranme, icon: <Star />, label: 'Team Tree' },


        ]
    },
    {
        eventKey: "10", path: "/upgrade-plan", icon: <AccountBalanceWallet />, label: 'Upgrade Buying', subMenu: [
            { path: "/upgrade-plan", icon: <Star />, label: 'Upgrade Buying Now' },

            { path: "/activation-ledger", icon: <Star />, label: 'Upgrade Buying History' },


        ]
    },

    {
        eventKey: "4", icon: <AccountBalanceWallet />, label: 'Airdrop', subMenu: [
            { path: "/report/staking_bonus", icon: <Star />, label: 'Staking Bonus' },
            // { path: "/report/token_income", icon: <Star />, label: 'Token  Income' },
            { path: "/report/booster_airdrop", icon: <Star />, label: 'Booster Airdrop' },
            { path: "/report/referral_airdrop", icon: <Star />, label: 'Referral Airdrop' },

            { path: "/report/structure_airdrop", icon: <Star />, label: 'Structure Airdrop' },
            { path: "/report/airdrop_reward", icon: <Star />, label: 'Airdrop Reward' },
            // { path: "/wallet-ledger", icon: <Star />, label: 'Stake Ledger' },
            { path: "/ledger", icon: <Star />, label: 'Airdrop Ledger' },
            { path: "/non-ledger", icon: <Star />, label: 'Staking/Booster Airdrop Ledger' },



        ]
    },

    
    { eventKey: "5", path: "/ledger", icon: <Description />, label: 'Ledger' },
    {
        eventKey: "6", icon: <Payment />, label: 'Airdrop Withdrawal', subMenu: [
            { path: "/payout-request", icon: <Star />, label: 'Working Airdrop Withdraw' },
            // { path: "/non-working-request", icon: <Star />, label: 'Staking Withdrawal' },
            { path: "/pay-history", icon: <Star />, label: 'Working Withdrawal History ' },
            // { path: "/non-pay-history", icon: <Star />, label: 'Staking Withdrawal History' }

        ]
    },

    {
        eventKey: "10", icon: <Payment />, label: 'Staking Bonus Withdraw', subMenu: [
            { path: "/non-working-request", icon: <Star />, label: 'Staking Bonus' },
            { path: "/non-pay-history", icon: <Star />, label: 'Staking Withdrawal History' }

        ]
    },
    { eventKey: "7", path: "/presentations", icon: <AutoStories />, label: 'Presentations' },
    {
        eventKey: "8", icon: <Help />, label: 'Support', subMenu: [
            { path: "/create-ticket", icon: <Star />, label: 'Create Ticket' },
            { path: "/ticket-history", icon: <Star />, label: 'Ticket History' },
            { path: "/presentations", icon: <Star />, label: 'Presentations' }
        ]
    },
    { eventKey: "9", path: "#", icon: <Logout />, label: 'Log out', onClick: logOut }
];
